import React, { useState, useEffect } from 'react';
import { User } from 'store/types/User';
import gold from 'image/icons/activity/collega-gold.svg';
import profile from 'image/icons/profile-placeholder.svg'
import ProfileItem, { ProfileSwitch } from './ProfileItem';
import ProfileWrapper from './ProfileWrapper';
import { ExternalLinks, DateFormat, FormFields, SkillsType, SubscriptionType, SubscriptionInfoType } from 'values/values';
import ConfirmModal from 'components/request-activities/modal/ConfirmModal';
import { ActivityModal } from 'store/types/ActivityModal';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Utility from 'utils/utils';
import Storage from '../../store/Storage';
import strings from 'values/strings';
import { useSelector, useDispatch } from 'react-redux';
import { IUserReducer, createTempUser, updateTempUser, setCurrentUser } from 'store/reducers/UserReducer';
import UserService from 'services/UserServices';
import AnalyticsService, { AnalyticsScreens } from 'services/AnalyticsService';
import Modal from 'components/common/Modal';
import ProfileSkills from './ProfileSkills';
import BasicCoWorker from './BasicCoWorker';
import SpecificSkillsBanner from './SpecificSkillsBanner';
import SpecificSkill from './SpecificSkill';
import ProfessionalSkill from './ProfessionalSkill';
import arrow from 'image/icons/nav/arrow-grey.svg';
import SubscriptionsEdit from './SubscriptionsEdit';
import { Subscription } from 'store/types/Subscriptions';


interface IProfile {
  currentUser: User;
}

const UserProfile: React.FC<IProfile> = ({ currentUser }) => {
  const [certified, setCertified] = useState(false)
  const [cancelSubscriptionConfirm, setCancelSubscriptionConfirm] = useState(false)
  const [skillModal, setSkillModal] = useState(false)
  const [specificSkillModal, setSpecificSkillModal] = useState(false)
  const [professionalSkillModal, setProfessionalSkillModal] = useState(false)
  const [subscriptionsModal, setSubscriptionsModal] = useState(false)
  const [cancelSubscriptionsPopup, setCancelSubscriptionsPopup] = useState(false)
  const [subscription, setSubscription] = useState<Subscription>()
  const [showWarnings, setShowWarnings] = useState(false);
  let [isLoading, setIsLoading] = useState(true)

  const location = useLocation()
  const history = useHistory();

  let [showSkillsButton, setShowSkillsButton] = useState<boolean>()
  let [updateResponse, updateError, updateRequest] = UserService.useUpdateUserAPI()
  let [cancelSubscriptionResponse, cancelSubscriptionError, cancelSubscriptionRequest] = UserService.useCancelSubscriptionAPI()
  let [cancelSubscriptionUserResponse, cancelSubscriptionUserError, cancelSubscriptionUserRequest] = UserService.useCancelSubscriptionUserAPI()

  const [skillsResponse, , skillsRequest] = UserService.useSearchSkillsAPI();
  let [userResponse, userError, userRequest] = UserService.useUserAPI()

  const tempUser: User | any = useSelector((state: IUserReducer) => state.user.tempUser);


  const dispatch = useDispatch();


  const cancelSubscriptionModal: ActivityModal = {
    copy: strings.userProfile.popup.text,
    cta: strings.userProfile.popup.action,
    ctaUndo: strings.userProfile.popup.close,
    onConfirm: (() => {
      setCancelSubscriptionConfirm(false)
      cancelSubscriptionRequest(currentUser!.last_payment!.id)
    }),
    onCancel: () => setCancelSubscriptionConfirm(false)
  }

  const warningsPopup: ActivityModal = {
    title: 'Aggiungere una nuova competenza?',
    copy: 'Se vuoi aggiungere una nuova competenza puoi acquistarle un pacchetto da 1 o da 3 competenze.',
    cta: 'Acquista',
    ctaUndo: 'Annulla',

    onConfirm: (() => {
      setShowWarnings(false)
      history.push(`/subscription?type=${SubscriptionInfoType.SKILLS}`)
    }),
    onCancel: () => setShowWarnings(false)
  }


  useEffect(() => {
    skillsRequest('');
  }, []);

  useEffect(() => {
    if (cancelSubscriptionUserResponse) {
      setCancelSubscriptionsPopup(false)

      userRequest()
    }
  }, [cancelSubscriptionUserResponse]);

  useEffect(() => {
    if (skillsResponse) setIsLoading(false);
  }, [skillsResponse]);

  useEffect(() => {
    if (location.state && location.state.showSkillModal) {
      setSkillModal(true);
    }
  }, [location.state]);

  let onCertified = () => {
    AnalyticsService.screenName(AnalyticsScreens.profileCertification)
    history.push(`/subscription?type=${SubscriptionInfoType.PROFESSIONAL}`)
  }

  useEffect(() => {
    AnalyticsService.screenName(AnalyticsScreens.profile)
  }, [])

  useEffect(() => {
    AnalyticsService.screenName(cancelSubscriptionConfirm ? AnalyticsScreens.popupCancelSubscription : AnalyticsScreens.profile)
  }, [cancelSubscriptionConfirm])

  useEffect(() => {
    if (certified) AnalyticsService.screenName(AnalyticsScreens.profileCertified)
  }, [certified])

  useEffect(() => {
    Storage.get(Storage.SHOW_SKILLS_BUTTONS).then((value: boolean) => {
      setShowSkillsButton(value === undefined ? true : value)
    }).catch(() => {
      return setShowSkillsButton(true)
    })
  })

  useEffect(() => {
    if (cancelSubscriptionResponse) {

      let user: any = {
        ...currentUser,
        last_payment: cancelSubscriptionResponse
      }

      dispatch(setCurrentUser(user))
    }
  }, [cancelSubscriptionResponse])

  useEffect(() => {
    dispatch(createTempUser(currentUser))
  }, [currentUser])


  const availableAddSkills = () => {
    let additionalSkillsSubscriptions = User.additionalSkillsSubscriptions(currentUser);
    let purchasedSkills = additionalSkillsSubscriptions.reduce((a: number, b: Subscription) => (a + b.purchased_skills!), 0)
    return purchasedSkills;
  };

  const availableProfessionalSkills = () => {
    let subscription = User.professionalSubscription(currentUser);
    let availableProfessionallSkills = subscription ? subscription.purchased_skills! : 0;
    return availableProfessionallSkills;
  };

  const handleCancelSubscription = (subscription?: Subscription) => {
    if (subscription && subscription.id) {
      cancelSubscriptionUserRequest(subscription.id);
    }
  };

  const cancelSubscriptionPopupView = (subscription?: Subscription) => {
    // Check if subscription exists and has an expiration date; default to now if not
    const expirationDate = subscription && subscription.expire_at ? moment(subscription.expire_at) : moment();

    // Format the expiration date
    const subscription_expiration_date = expirationDate.format(DateFormat.profile);

    // Build the subscription description based on the subscription status
    const isUnsubscribed = (subscription && subscription.unsubscribed !== undefined) ? subscription.unsubscribed : false; // Default to false if undefined // Default to false if undefined
    const subscriptionCost = (subscription && subscription.cost !== undefined) ? subscription.cost : 0; // Default to 0 if cost is undefined


    const subscription_description = isUnsubscribed
      ? `L'abbonamento scadrà il ${subscription_expiration_date}. Potrai continuare ad utilizzare l'abbonamento fino alla data di scadenza.`
      : `L'abbonamento verrà rinnovato automaticamente il ${subscription_expiration_date} al costo di ${subscriptionCost}€.`; // Use the cost variable


    // Create the activity modal for canceling the subscription
    const cancelSubscription: ActivityModal = {
      title: 'Attenzione',
      copy: subscription_description,
      cta: 'Disdici',
      ctaUndo: 'Annulla',
      onConfirm: () => {
        handleCancelSubscription(subscription)
      },
      onCancel: () => setCancelSubscriptionsPopup(false) // Close the popup
    };

    return cancelSubscription;
  };

  const dividerColor = () => {
    return User.isGold(currentUser) ? '#A17644' : User.isProfessional(currentUser) ? '#f6d560' : '#208a86';
  }


  return (
    <>
      <div className={'profile__container'}>
        <h2>{strings.userProfile.title}</h2>
        <div className={'divider'} style={{ backgroundColor: dividerColor() }}></div>

        <div className={'__profile__container'}>
          <div className={'card __profile'}>
            <span className={'name'}>{User.fullName(currentUser)}</span>

            <div className={'role__container noSkills'}>
              <span className={'role'}>{Utility.localised(currentUser.profile.type)}</span>
            </div>

            <div className={'panel__icon shadow'}>
              <img src={currentUser.profile.picture ? currentUser.profile.picture : profile} alt={'profile'} />
            </div>
          </div>

          {User.isProfessional(currentUser) &&
            <div className={'card certified__container'} onClick={currentUser.last_payment ? () => setCertified(true) : undefined}>
              <img src={gold} alt="collega" />
              <div>
                <h3>{strings.userProfile.certified.title}</h3>
                <p>{strings.userProfile.certified.subtitle}<b>{strings.userProfile.certified.subtitle2}</b> fino al <b>{moment(User.professionalSubscription(currentUser)!.expire_at).format(DateFormat.full)}</b></p>
              </div>
            </div>
          }

          <BasicCoWorker currentUser={currentUser} />

          {User.additionalSkillsSubscription(currentUser) == undefined && <SpecificSkillsBanner currentUser={currentUser} />}

          {User.additionalSkillsSubscription(currentUser) && <SpecificSkill
            currentUser={currentUser}
            onPress={(canAdd) => {
              if (canAdd) {
                setSpecificSkillModal(true)

              } else {
                setShowWarnings(true)
              }
            }}
            onEdit={() => {
              setSpecificSkillModal(true)
            }}
          />}


          {!User.isProfessional(currentUser) && <div className={'card certify__container'}>
            <div className={'element'}>
              <div className={'element__img'}>
                <img src={gold} alt="collega" />
                <h3>{strings.userProfile.toCertify.title}</h3>
              </div>
              <p dangerouslySetInnerHTML={{ __html: strings.userProfile.toCertify.subtitle }}></p>
              <div className={'button__'}>
                <button className={'button-primary'} onClick={onCertified}>{strings.userProfile.toCertify.cta}</button>
              </div>
            </div>
          </div>}

          {User.professionalSubscription(currentUser) && <ProfessionalSkill currentUser={currentUser}
            onPress={() => {
              setProfessionalSkillModal(true)
            }}
            onEdit={() => {
              setProfessionalSkillModal(true)
            }} />}

          {/* {currentUser.is_certified && !currentUser.profile.is_complete && <div className={'card complete__container'}>
          <div className={'element'}>
            <div>
              <h3>{strings.userProfile.toComplete.title}</h3>
              <p>{strings.userProfile.toComplete.subtitle}</p>
            </div>
            <NavLink to={'/profile-complete'}>
              <button className={'button-primary'} onClick={() => AnalyticsService.event(AnalyticsEvents.profileCompleteClicked, { email: currentUser!.email })} >{strings.userProfile.toComplete.cta}</button>
            </NavLink>
          </div>
        </div>} */}

          {currentUser.is_certified && showSkillsButton && !currentUser.profile.lawfirm.city2 &&
            <div className={'card certify__container'}>
              <div className={'element'}>
                <div className={'element__img'}>
                  <img src={gold} alt="collega" />
                  <h3>{strings.userProfile.isCertified.title}</h3>
                </div>

                <p>{strings.userProfile.isCertified.subtitle}</p>
                <div className={'button__ double'}>

                  {/* {showSkillsButton && <NavLink to={'/profile-skills'}>
                  <button className={'button-primary'} >{strings.userProfile.isCertified.cta}</button>
                </NavLink>} */}

                  {!currentUser.profile.lawfirm.city2 && <NavLink to={'/profile-locations'}>
                    <button className={'button-primary'} >{strings.userProfile.isCertified.cta2}</button>
                  </NavLink>}

                </div>
              </div>
            </div>
          }



          <div className={'card info__container'}>
            <ProfileWrapper title={strings.profileEdit.step.personal.userTitle} cta={true} is_complete={currentUser && currentUser.profile.is_complete}>
              <ProfileItem label={strings.fieldLabelEmailSummary} content={currentUser.email} />
              {/* <ProfileItem label={strings.fieldLabelPasswordSummary} content={pass ? strings.profileEdit.step.personal.pass : strings.profileEdit.step.personal.passhide} hide={<span className={pass ? 'unhide hide' : 'unhide'} onClick={() => setPass(!pass)}></span>} /> */}
              <ProfileItem label={strings.fieldLabelPhoneSummary} content={currentUser.phone} />
              {currentUser.birth_date && <ProfileItem label={strings.profileEdit.step.personal.birth} content={moment(currentUser.birth_date).format(DateFormat.full)} />}
              {currentUser.fiscal_code && <ProfileItem label={strings.fieldLabelFiscalCodeSummary} content={currentUser.fiscal_code} />}
            </ProfileWrapper>

            <ProfileWrapper title={strings.profileEdit.step.professional.userTitle}>
              <ProfileItem label={strings.profileEdit.step.professional.place} content={currentUser.profile.city!.display_name} />
              {User.isProfessional(currentUser) && currentUser.profile.lawfirm.city2 && <ProfileItem hasIcon={true} iconType={SubscriptionType.PROFESSIONAL} label={strings.profileEdit.step.professional.place2} content={currentUser.profile.lawfirm.city2.display_name} />}
              {currentUser.profile.vat && <ProfileItem label={strings.profileEdit.step.professional.input.vat.label} content={currentUser.profile.vat} />}
              {currentUser.profile.pec && <ProfileItem label={strings.profileEdit.step.professional.input.pec.label} content={currentUser.profile.pec} />}
              {currentUser.profile.register_number && <ProfileItem label={strings.profileEdit.step.professional.input.register_number.label} content={currentUser.profile.register_number} />}
              {currentUser.profile.coa_office && <ProfileItem label={strings.profileEdit.step.professional.input.coa.label} content={currentUser.profile.coa_office.name} />}
            </ProfileWrapper>

            <ProfileWrapper title={strings.profileEdit.step.lawfirm.userTitle}>
              <ProfileItem label={strings.profileEdit.step.lawfirm.input.name.label} content={currentUser.profile.lawfirm.name} />
              <ProfileItem label={strings.profileEdit.step.lawfirm.input.lawyer.label} content={currentUser.profile.lawfirm.lawyer} />
              {User.isGold(currentUser) && currentUser.profile.lawfirm.description && <ProfileItem hasIcon={true} iconType={SubscriptionType.GOLD} label={strings.profileEdit.step.lawfirm.input.description.label} content={currentUser.profile.lawfirm.description} />}
              <ProfileItem label={strings.profileEdit.step.lawfirm.input.city2.userLabel} content={currentUser.profile.lawfirm.city!.display_name} />
              {currentUser.profile.lawfirm.address && <ProfileItem label={strings.profileEdit.step.lawfirm.input.address.label} content={currentUser.profile.lawfirm.address} />}

              {currentUser.profile.lawfirm.zip && <ProfileItem label={strings.profileEdit.step.lawfirm.input.cap.label} content={currentUser.profile.lawfirm.zip} />}
              {currentUser.profile.lawfirm.phone && <ProfileItem label={strings.profileEdit.step.lawfirm.input.phone.label} content={currentUser.profile.lawfirm.phone} />}
              {currentUser.profile.lawfirm.fax && <ProfileItem label={strings.profileEdit.step.lawfirm.input.fax.label} content={currentUser.profile.lawfirm.fax} />}
            </ProfileWrapper>
          </div>

          <div className={'card info__container'}>
            <div>
              <span className='title'>{strings.userProfile.settings.title}</span>

            </div>

            <div className='title-cta-container' style={{ cursor: 'pointer' }} onClick={() => {
              setSubscriptionsModal(true)
            }}>
              <span className='title-cta'>{'Gestisci i tuoi abbonamenti'}</span>
              <img src={arrow} alt={'arrow'} style={{ cursor: 'pointer' }} className='arrow-icon' />
            </div>

            <ProfileWrapper title={''}>
              <ProfileSwitch
                content={strings.userProfile.settings.email}
                checked={tempUser && tempUser.email_enabled}
                onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.checked
                  if (value !== undefined)
                    await dispatch(updateTempUser(FormFields.email_enabled, value))
                  updateRequest({ ...tempUser, email_enabled: value })
                }}
              />

              <ProfileSwitch
                content={strings.userProfile.settings.notification}
                checked={tempUser && tempUser.notifications_enabled}
                onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                  let value = event.target.checked
                  if (value !== undefined)
                    await dispatch(updateTempUser(FormFields.notifications_enabled, value))
                  updateRequest({ ...tempUser, notifications_enabled: value })
                }}
              />
            </ProfileWrapper>
          </div>

          <div className={'card info__container'}>
            <ProfileWrapper title={strings.userProfile.terms.title}>
              <ProfileItem content={strings.userProfile.terms.terms} terms={ExternalLinks.Terms} />
              <ProfileItem content={''} />
              <ProfileItem content={strings.userProfile.terms.privacy} terms={ExternalLinks.Privacy} />
            </ProfileWrapper>
          </div>
          <div className={'button__container'}>
            <NavLink to={'/profile-edit'}>
              <button className={'button-secondary'}>{strings.userProfile.cta}</button>
            </NavLink>
          </div>
        </div>
        {/* {certified && <ConfirmModal content={certifiedType} />} */}
        {cancelSubscriptionConfirm && <ConfirmModal content={cancelSubscriptionModal} />}

      </div>

      {skillModal && !isLoading &&
        <Modal onClose={() => setSkillModal(false)}>
          <ProfileSkills maxCheck={1} type={SkillsType.BASIC} allSkills={skillsResponse} />
        </Modal>
      }

      {specificSkillModal && !isLoading &&
        <Modal onClose={() => setSpecificSkillModal(false)}>
          <ProfileSkills maxCheck={availableAddSkills()} type={SkillsType.SKILLS} allSkills={skillsResponse} />
        </Modal>
      }

      {professionalSkillModal && !isLoading &&
        <Modal onClose={() => setProfessionalSkillModal(false)}>
          <ProfileSkills maxCheck={availableProfessionalSkills()} type={SkillsType.PROFESSIONAL} allSkills={skillsResponse} />
        </Modal>
      }

      {subscriptionsModal && !isLoading &&
        <Modal onClose={() => setSubscriptionsModal(false)}>
          <SubscriptionsEdit onPress={(subscription) => {
            setSubscription(subscription)
            setCancelSubscriptionsPopup(true)
          }} />
        </Modal>
      }

      {cancelSubscriptionsPopup && subscription && <ConfirmModal content={cancelSubscriptionPopupView(subscription)} />}

      {showWarnings && <ConfirmModal content={warningsPopup} />}
    </>
  );
};

export default UserProfile;