import React from 'react';
import { MenuItem } from '../../store/types/MenuItem';
import dashboard from 'image/icons/nav/dashboard-icon.svg';
import request from 'image/icons/nav/activity-request-icon.svg';
import proposal from 'image/icons/nav/activity-proposal-icon.svg';
import assign from 'image/icons/nav/activity-assignments-icon.svg';
import news from 'image/icons/nav/news-icon.svg';
import greyArrow from 'image/icons/nav/arrow-grey.svg';

import MenuWrapper from './MenuWrapper';
import MenuElement from './MenuElement';
import { useSelector } from 'react-redux';
import { IAppReducer } from 'store/reducers/AppReducer';
import strings from 'values/strings';
import AnalyticsService, { AnalyticsEvents } from 'services/AnalyticsService';
import { ExternalLinks } from 'values/values';


const homeMenu: MenuItem[] = [
  {
    link: '/dashboard',
    title: strings.navMenu.home,
    order: 1,
    target: undefined,
    icon: dashboard
  },
]
const activityMenu: MenuItem[] = [
  {
    link: '/activity-proposal',
    title: strings.navMenu.activity_proposal,
    order: 1,
    target: undefined,
    icon: proposal
  },
  {
    link: '/activity-request',
    title: strings.navMenu.activity_request,
    order: 2,
    target: undefined,
    icon: request
  },
  {
    link: '/activity-assignments',
    title: strings.navMenu.activity_assignements,
    order: 3,
    target: undefined,
    icon: assign
  },
]

const servicesMenu: MenuItem[] = [
  {
    analyticsEvent: () => AnalyticsService.event(AnalyticsEvents.shop, undefined),
    link: ExternalLinks.CollegaShop,
    title: strings.navMenu.shop,
    order: 1,
    target: '_blank',
  },
  {
    link: '/agenda',
    title: strings.navMenu.agenda,
    order: 1,
    target: undefined,
  },
  {
    link: '/subscriptions',
    title: strings.navMenu.subscriptions,
    order: 1,
    target: undefined,
  },
  {
    link: '/records',
    title: strings.navMenu.records,
    order: 2,
    target: undefined,
  },
]

const newsMenu: MenuItem[] = [
  {
    link: '/news',
    title: strings.navMenu.news,
    order: 1,
    target: undefined,
    icon: news
  },
];

const arrowMenu: MenuItem[] = [
  {
    analyticsEvent: () => AnalyticsService.event(AnalyticsEvents.share, undefined),
    link: 'mailto:' + ' ' + '?subject=' + strings.navMenu.shareSubject + '&body=' + strings.navMenu.shareMessage,
    title: strings.navMenu.share,
    order: 1,
    target: '_blank',
    arrow: greyArrow
  },
  {
    analyticsEvent: () => AnalyticsService.event(AnalyticsEvents.support, undefined),
    link: 'mailto:' + strings.navMenu.support_email.address + '?subject=' + strings.navMenu.support_email.subject + '&body=' + strings.navMenu.support_email.message,
    title: strings.navMenu.problem,
    order: 2,
    target: '_blank',
    arrow: greyArrow
  },
]


const NavMenu: React.FC = () => {
  const menuItem = useSelector((state: IAppReducer) => state.app.menu);

  const externalEvent = () => {
    switch (menuItem && menuItem.length) {
      case 0: return 'no-event'
      case 1: return 'one-event'
      case 2: return 'two-event'
      default: return undefined
    }
  }

  return (
    <div className={`${'navmenu__container'} ${externalEvent()}`}>
      <div className={'navmenu__content'}>

        {/* Home */}
        <MenuWrapper menuStyle={'navmenu__service service'}>
          {homeMenu.map((item) => (<MenuElement key={item.title} item={item} />))}
        </MenuWrapper>

        {/* Activities */}
        <MenuWrapper menuStyle={'navmenu__service service no__border'}>
          {activityMenu.map((item) => (<MenuElement key={item.title} item={item} />))}
        </MenuWrapper>

        {/* Services and Products */}
        <MenuWrapper menuStyle={'navmenu__service service courses'}>
          <p>{strings.navMenu.collegaTraining}</p>

          {/* Static items */}
          {servicesMenu.map((item) => (<MenuElement key={item.title} item={item} />))}

          {/* Dynamic items */}
          {menuItem && menuItem.map((item) => {
            let _item = item
            item.analyticsEvent = () => AnalyticsService.event(AnalyticsEvents.dynamic_link, { link: item.link })
            return <MenuElement key={item.title} item={_item} isDynamic={false} target={'_blank'} />
          })}
        </MenuWrapper>

        {/* News */}
        <MenuWrapper menuStyle={'navmenu__service service news'}>
          {newsMenu.map((item) => (<MenuElement key={item.title} item={item} />))}
        </MenuWrapper>

        {/* Arrow Menu */}
        <MenuWrapper menuStyle={'navmenu__service arrow'}>
          {arrowMenu.map((item) => (<MenuElement key={item.title} item={item} />))}
        </MenuWrapper>
      </div>
    </div>
  )
};

export default NavMenu;
