import { Profile } from './Profile';
import Utility from '../../utils/utils';
import { Subscription } from './Subscriptions';
import _ from 'lodash';
import { SubscriptionType } from 'values/values';


export class User {

  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  birth_date?: string;
  phone?: string;
  fiscal_code?: string;
  password1?: string;
  password2?: string;
  under46: boolean = false;
  terms: boolean = false;
  privacy: boolean = false;
  gdpr: boolean = false;
  trial_expires_at?: string;
  subscriptions: Array<Subscription> = []

  profile: Profile = new Profile()

  created_at?: string

  is_certified: boolean = false
  is_aiga: boolean = false

  email_enabled?: boolean
  notifications_enabled?: boolean

  last_payment?: LastPayment

  temp_demand?: string
  is_new: boolean = true
  missing_popups: Array<{ id: number, name: string }> = []



  static fullNameMasked = (user: User): string => {
    return user.first_name + ' ' + user.last_name!.charAt(0) + '.'
  }

  static fullName = (user: User): string => {
    return user.first_name + ' ' + user.last_name
  }

  // Subscriptions

  static isGold = (user?: User) => {
    if (user === undefined) { return false; }
    return user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.GOLD).length > 0;
  }

  static isProfessional = (user?: User) => {
    if (user === undefined) { return false; }
    return user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.PROFESSIONAL).length > 0;
  }

  static additionalSkillsSubscription = (user?: User): Subscription | undefined => {
    if (user === undefined) { return undefined; }
    return _.first(user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.SKILLS));
  }

  static additionalSkillsSubscriptions = (user?: User): Subscription[] => {
    if (user === undefined) { return []; }
    return user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.SKILLS)
  }


  static goldSubscription = (user?: User): Subscription | undefined => {
    if (user === undefined) { return undefined; }
    return _.first(user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.GOLD));
  }

  static professionalSubscription = (user?: User): Subscription | undefined => {
    if (user === undefined) { return undefined; }
    return _.first(user!.subscriptions.filter((subscription: Subscription) => subscription.type === SubscriptionType.PROFESSIONAL));
  }

  checkDateField() {
    return // !!this.birth_year && !!this.birth_day && this.birth_month !== '-1';
  }

  checkDateValidity() {
    return true
  }

  validateType() {
    return this.profile.type !== undefined
  }

  validateName() {
    return this.first_name !== undefined && this.last_name !== undefined;
  }

  validateCredentials() {
    return Utility.validateEmail(this.email) && Utility.validatePassword(this.password1)
  }

  validateFlag() {
    return this.privacy && this.terms;
  }

  checkSignUpValidity() {
    return this.validateType() && this.validateName() && this.validateFlag() &&
      Utility.validateEmail(this.email) && Utility.validatePassword(this.password1) &&
      Utility.validatePhone(this.phone);
  }
}

export class LastPayment {
  id?: number
  expire_at?: string
  subscription_id?: string
  payment_type?: PaymentType
  renewal?: boolean
}


export class PaymentType {
  id?: number
  description?: string
  price?: string
  months?: number
  status?: boolean
  plan_id?: string
}