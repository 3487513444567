import React, { FC, useState, useEffect } from 'react';
import { User } from 'store/types/User';
import edit from '../../image/edit.svg';
import QualificationOptions from './QualificationOption';
import ProfileSkills from './ProfileSkills';
import Modal from 'components/common/Modal';
import { Skill } from 'store/types/Profile';
import questionMark from '../../image/question-mark.svg';
import strings from 'values/strings';
import UserService from 'services/UserServices';
import SkillProfileItem from './SkillProfileItem';
import colors from 'values/colors';
import { useHistory } from 'react-router-dom';
import { SkillsType } from 'values/values';

type BasicCoWorkerProps = {
  currentUser: User;
}

const BasicCoWorker: FC<BasicCoWorkerProps> = ({ currentUser }) => {
  const [basicSkillModal, setBasicSkillModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [skillsResponse, , skillsRequest] = UserService.useSearchSkillsAPI();
  const genericSkills = currentUser && currentUser.profile.skills.filter(skill => skill.is_main === true)

  useEffect(() => {
    skillsRequest('');
  }, []);

  useEffect(() => {
    if (skillsResponse) setIsLoading(false);
  }, [skillsResponse]);



  const basicSkills = currentUser.profile.skills
    .filter(skill => skill.is_main === true)
    .sort((a, b) => a.name === 'Generica' ? -1 : (b.name === 'Generica' ? 1 : 0));

  return (
    <>
      <div className={"basic-worker-container"}>
        <div className={"basic-worker-header"}>
          <span>{strings.profile.basicCoWorker.title}</span>
          {basicSkills.length > 1 && (
            <img
              src={edit}
              alt="edit-icon"
              onClick={() => {
                setBasicSkillModal(true)
              }}
            />
          )}
        </div>

        <div className={"qualification-container"}>
          <p>{strings.profile.basicCoWorker.qualificationTitle}</p>
          <div style={{ paddingTop: 4, paddingBottom: 20 }}>
            <span className='span'>{strings.profile.basicCoWorker.qualificationSubtitle}</span>
          </div>
          <div className={"qualification-options-wrapper"}>
            <QualificationOptions currentUser={currentUser} />
          </div>
        </div>

        {/* <div className='secondary-divider' /> */}

        {/* SKILLS */}
        <div className={"skill-container"}>
          <div className={"skill-wrapper"}>
            <div className={'skill-container'}>

              <div style={{ paddingTop: 4, paddingBottom: 20 }}>
                <span className='span'>{"Le tue competenze principali"}</span>
              </div>

              {basicSkills.map((skill: Skill, index: number) => {
                return (
                  <React.Fragment key={skill.id}>
                           {index > 0 && <div className='secondary-divider' />}

                    <SkillProfileItem
                      skill={skill}
                      onPress={() => {
                        setBasicSkillModal(true)
                      }}
                      isGold={User.isGold(currentUser)}
                      disabled={false}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          {basicSkills.length <= 1 && (
            <div className="add-skill-button">
              <button onClick={() => setBasicSkillModal(true)}>
                {strings.profile.basicCoWorker.addSkillButton}
              </button>
            </div>
          )}
        </div>


      </div>

      {basicSkillModal && !isLoading &&
        <Modal onClose={() => setBasicSkillModal(false)}>
          <ProfileSkills maxCheck={1} type={SkillsType.BASIC} allSkills={skillsResponse} />
        </Modal>
      }
    </>
  );
};

export default BasicCoWorker;
