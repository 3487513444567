import React, { FC, useState, useEffect } from 'react';
import { User } from 'store/types/User';
import { Skill } from 'store/types/Profile';
import EmptySpecificSkillBanner from './EmptySpecificSkillBanner';
import UserService from 'services/UserServices';
import strings from 'values/strings';
import { useHistory } from 'react-router-dom';
import { SubscriptionInfoType } from 'values/values';

type SpecificSkillsBannerProps = {
  currentUser: User;
}

const SpecificSkillsBanner: FC<SpecificSkillsBannerProps> = ({ currentUser }) => {
  const [specificSkillBanner, setSpecificSkillBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [skillsResponse, , skillsRequest] = UserService.useSearchSkillsAPI();
  let history = useHistory()

  useEffect(() => {
    skillsRequest('');
  }, []);

  useEffect(() => {
    if (skillsResponse) setIsLoading(false);
  }, [skillsResponse]);

  const specificSkills = currentUser.profile.skills.filter((item) => item.type === 'ADDITIONAL_SKILLS') || [];

  return (
    <>
      {/* TODO: Handle new logic for payments */}
      {specificSkills.length < 1 ? (
        <EmptySpecificSkillBanner
          isForMainSkill={false}
          action={() => setSpecificSkillBanner(true)}
        />
      ) : (
        <div className={"filled-banner-container"}>
          <div className={"filled-banner-header"}>
            <span>Competenze specifiche</span>
            {/* <img
              src={edit}
              alt="edit-icon"
              onClick={() => setSpecificSkillBanner(true)}
            /> */}
          </div>
          <div className={"skill-container"}>
            {specificSkills.map((skill: Skill) => {
              return (
                <span key={skill.id} className={"skill-banner"}>
                  {skill.name}
                </span>
              );
            })}
          </div>
          <div className="add-skill-button">
            <button onClick={() => setSpecificSkillBanner(true)}>
              {strings.profile.basicCoWorker.addSkillButton}
            </button>
          </div>
        </div>
      )}

      {specificSkillBanner && !isLoading && (
        history.push(`/subscription?type=${SubscriptionInfoType.SKILLS}`)
      )}
    </>
  );
};

export default SpecificSkillsBanner;




// import React, { FC, useState, useEffect } from 'react';
// import { User } from 'store/types/User';
// import ProfileSkills from './ProfileSkills';
// import Modal from 'components/common/Modal';
// import { Skill } from 'store/types/Profile';
// import edit from '../../image/edit.svg';
// import EmptySpecificSkillBanner from './EmptySpecificSkillBanner';
// import UserService from 'services/UserServices';
// import strings from 'values/strings';


// // Soluzione alternativa per ButtonCustom (puoi usare un normale button HTML)
// const ButtonCustom = ({title, icon, type, style, onClick}: any ) => (
//   <button className={style} onClick={onClick}>
//     {icon && <img src={icon} alt="icon" />} {title}
//   </button>
// );

// // Soluzione alternativa per DividerCustom (usa un semplice div con border)
// const DividerCustom = () => <div style={{ borderBottom: '1px solid #ccc', margin: '8px 0' }}></div>;

// // Soluzione alternativa per SkillProfileItem (visualizza solo nome della skill per semplicità)
// const SkillProfileItem = ({skill, isGold, onClick}: any) => (
//   <div onClick={onClick} style={{ padding: '8px', backgroundColor: isGold ? 'gold' : '#f9f9f9' }}>
//     {skill.name}
//   </div>
// );

// // Dati utente di esempio per simulare i dati presi da Redux o da API
// const exampleUser = {
//   profile: {
//     skills: [
//       { id: 1, name: 'React', type: 'additional_skills', is_main: false },
//       { id: 2, name: 'Node.js', type: 'additional_skills', is_main: false },
//       { id: 3, name: 'TypeScript', type: 'main_skill', is_main: true },
//     ],
//   },
//   additionalSkillsSubscription: { renewed: true },
// };

// // Simula la funzione per aggiornare il profilo
// const updateProfile = async (field: any, skills: any) => {
// };

// // Simula la funzione per aprire la schermata delle competenze (qui solo per esempio)
// const onOpenSkillsScreen = async (context: any, callback: any) => {
//   const skills = [
//     { id: 4, name: 'JavaScript' },
//     { id: 5, name: 'CSS' },
//   ]; // Simulazione delle nuove competenze
//   await callback(skills);
// };


// type SpecificSkillsBannerProps = {
//   currentUser: User;
// }

// const SpecificSkillsBanner: FC<SpecificSkillsBannerProps> = ({ currentUser }) => {  const [showFieldPopup, setShowFieldPopup] = useState(false);
//   const [showPopupSkillAdd, setShowPopupSkillAdd] = useState(false);
//   const user = exampleUser; // Simula l'utente preso dallo stato (come Redux)

//   // Funzione che calcola quante competenze aggiuntive sono disponibili
//   const availableAddSkills = () => {
//     return 3 - user.profile.skills.filter((item) => item.type === 'additional_skills').length;
//   };

//   const renderSpecificSkills = () => {
//     return (
//       <div style={{ padding: '16px', border: '1px solid #ddd', borderRadius: '8px' }}>
//         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
//           {/* Simulazione immagine */}
//           <img src="https://via.placeholder.com/24" alt="collega icon" style={{ marginRight: '8px' }} />

//           <h3 style={{ flex: 1, margin: 0 }}>Competenze aggiuntive</h3>

//           {/* Solo se l'utente ha rinnovato l'abbonamento */}
//           {user.additionalSkillsSubscription.renewed && (
//             <ButtonCustom
//               icon="https://via.placeholder.com/24"
//               type="clear"
//               style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
//               onClick={() => {
//                 onOpenSkillsScreen('specific', async (skills: any) => {
//                   await updateProfile('skills', skills);
//                 });
//               }}
//             />
//           )}
//         </div>

//         <div>
//           {/* Mostra le competenze aggiuntive */}
//           {user.profile.skills
//             .filter((item) => item.type === 'additional_skills')
//             .map((skill, index, array) => {
//               const isLast = index === array.length - 1;
//               return (
//                 <div key={skill.id} style={{ flex: 1 }}>
//                   <SkillProfileItem
//                     skill={skill}
//                     isGold={true} // Aggiungi logica per `isGold` se necessario
//                     onClick={() => setShowFieldPopup(true)}
//                   />
//                   {!isLast && <DividerCustom />}
//                 </div>
//               );
//             })}
//         </div>

//         {/* Mostra quanti skills possono ancora essere aggiunti */}
//         {availableAddSkills() > 0 && (
//           <p style={{ color: '#777', fontSize: '14px' }}>
//             Puoi aggiungere ancora {availableAddSkills()}{' '}
//             {availableAddSkills() === 1 ? 'competenza' : 'competenze'}.
//           </p>
//         )}

//         {/* Bottone per selezionare o aggiungere competenze */}
//         <ButtonCustom
//           title={
//             user.profile.skills.filter((item) => item.is_main === false).length < 3
//               ? 'Seleziona competenze'
//               : 'Aggiungi competenze'
//           }
//           type="outline"
//           style={{ padding: '8px 16px', border: '1px solid #333', cursor: 'pointer', marginTop: '8px' }}
//           onClick={() => {
//             if (availableAddSkills() === 0) {
//               setShowPopupSkillAdd(true);
//             } else {
//               onOpenSkillsScreen('specific', async (skills: any) => {
//                 await updateProfile('skills', skills);
//               });
//             }
//           }}
//         />
//       </div>
//     );
//   };

//   return <>{renderSpecificSkills()}</>;
// };

// export default SpecificSkillsBanner;