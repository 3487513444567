import React, { FC } from 'react';
import { User } from 'store/types/User';
import edit from '../../image/edit.svg';
import collega from '../../image/collega.svg';
import SkillProfileItem from './SkillProfileItem';
import { Subscription } from 'store/types/Subscriptions';
import { Skill } from 'store/types/Profile';

type Props = {
    currentUser?: User;
    onPress: (canAdd: boolean) => void
    onEdit?: () => void
}

const SpecificSkill: FC<Props> = (props) => {

    const additionalSkills = () => {
        if (props.currentUser === undefined) { return [] }
        return props.currentUser.profile.skills.filter((item: Skill) => item.type === 'ADDITIONAL_SKILLS');
    };

    const additionalSkillsSubscriptions = User.additionalSkillsSubscriptions(props.currentUser);

    let availableAddSkills = (): number => {
        if (!props.currentUser || !props.currentUser.profile || !props.currentUser.profile.skills) return 0

        let purchasedSkills = additionalSkillsSubscriptions.reduce((a: number, b: Subscription) => (a + b.purchased_skills!), 0)
        return purchasedSkills - additionalSkills().length;
    }

    let hasSkillsToSelect = () => {
        return availableAddSkills() != undefined && availableAddSkills()! > 0
    }

    return (
        <>
            <div className="specific-container">
                <div className="specific-header">
                    {/* Left side (Icon and text) */}
                    <div className="left-content">
                        <img src={collega} alt="collega icon" style={{ marginRight: '8px', width: 20, height: 20 }} />
                        <span>Competenze aggiuntive</span>
                    </div>

                    {/* Check if some of subscription is renewed */}
                    {additionalSkillsSubscriptions.some((sub) => sub.renewed == true) && (
                        <img
                            src={edit}
                            alt="edit-icon"
                            onClick={props.onEdit}
                        />
                    )}

                </div>

                <div>
                    {/* Show additional skill */}
                    {additionalSkills().map((skill, index, array) => {
                        const isLast = index === array.length - 1;
                        return (
                            <>
                                <div key={skill.id} style={{ flex: 1 }}>
                                    <SkillProfileItem
                                        skill={skill}
                                        isGold={User.isGold(props.currentUser)}
                                        onPress={() => props.onPress(hasSkillsToSelect())}
                                    />
                                </div>
                                {!isLast && <div className='third-divider' />}
                            </>
                        );
                    })}

                    {hasSkillsToSelect() && (
                        <p className='text'>
                            Puoi aggiungere ancora {availableAddSkills()}{' '}
                            {availableAddSkills() === 1 ? 'competenza' : 'competenze'}.
                        </p>
                    )}

                    <div className={'button__'}>
                        <button className={'button-secondary'} onClick={() => props.onPress(hasSkillsToSelect())}>
                            {hasSkillsToSelect() ? 'Seleziona competenze' : 'Aggiungi altre competenze'}
                        </button>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SpecificSkill;




