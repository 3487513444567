const palette = {
  blue: '#1E3261',
  dark_blue: '#2F3047',
  highlited_blue: '#3C64C2',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#82849D',
  border_gray: '#F3F3F3',
  pager_gray: '#EAE9F2',
  light_gray: '#DADAE6',
  lightest_gray: '#F7F8FF',
  matt_black: '#1E1E1E60',
  red: '#F37E6F',
  overlay: '#23376670',
  highlited: '#3C64C210',
  event_hilighted: '#F5F7FC',
  orange: '#FFB760',
  green: '#208A86',
  purple: '#9C6BF3',
  brown: '#A07855',
  dark_gray: '#82849D',
  popup: '#00000060',
  tooltip: '#00000012',
  loader: '#00000030',
  dark_green: '#208A86'


};

const colors = {
  primary: palette.blue,
  primary_dark: palette.dark_blue,
  background: palette.white,
  white: palette.white,
  black: palette.black,
  gray: palette.gray,
  light_gray: palette.light_gray,
  lightest_gray: palette.lightest_gray,
  disabled: palette.lightest_gray,
  shadow: palette.matt_black,
  placeholder: palette.light_gray,
  border: palette.border_gray,
  error: palette.red,
  default_page: palette.pager_gray,
  drawer_overlay: palette.overlay,
  event_highlited: palette.event_hilighted,
  day_highlited: palette.highlited_blue,
  //
  hearing: palette.brown,
  appointment: palette.green,
  fulfillement: palette.purple,
  notice: palette.orange,
  tooltipText: palette.dark_gray,
  popup_overlay: palette.popup,
  tooltip_overlay: palette.tooltip,
  loader_overlay: palette.loader,
  check_terms: palette.dark_green
};

export default colors;
