import React, { useState, useEffect } from 'react';
import InputAutosuggest from '../common/AutoSuggest';
import SelectOption from '../../store/types/SelectionOption';
import EventsService from 'services/EventService';
import _ from 'lodash';
import strings from 'values/strings';

export enum RequestFieldType {
    activity, office, subject, section, judge, skill
}

interface Props {
    type: RequestFieldType;
    double?: boolean;
    value?: any;
    onChange?: (value?: any) => void;
    onSelectedObject?: (object: any) => void;
}

const RequestField: React.FC<Props> = ({ type, double, value, onChange, onSelectedObject }) => {

    let [offices, , officesRequest] = EventsService.useSearchOfficesAPI();
    let [activities, , activitiesRequest] = EventsService.useSearchActivitiesDemandAPI();
    let [subjects, , subjectsRequest] = EventsService.useSearchSubjectsAPI();
    let [skills, , skillsRequest] = EventsService.useSearchSkillsAPI();

    const [tempValue, setTempValue] = useState<any>('');
    const [selectedObj, setSelectedObj] = useState<any>();
    const [error, setError] = useState<any>();

    let canBeText = () => {
        switch (type) {
            case RequestFieldType.activity: return true;
            case RequestFieldType.office: return false;
            case RequestFieldType.subject: return true;
            case RequestFieldType.section: return true;
            case RequestFieldType.judge: return true;
            case RequestFieldType.skill: return false; 
        }
    };

    useEffect(() => {
        if (value) setTempValue(parseValue(value));
    }, [value]);

    useEffect(() => {
        if (selectedObj && onSelectedObject) onSelectedObject(selectedObj);
    }, [selectedObj]);

    useEffect(() => {
        if (!canBeText()) { 
            hasObject(); 
            return;
        }

        if (_.isEmpty(tempValue) && onChange) { 
            onChange(new SelectOption(undefined, undefined)); 
            return;
        }

        setSelectedObj(undefined);
        if (onChange) onChange(new SelectOption(tempValue, parseValue(tempValue)));
    }, [tempValue]);

    let request = (v: string) => {
        switch (type) {
            case RequestFieldType.activity: return activitiesRequest(v);
            case RequestFieldType.office: return officesRequest(v);
            case RequestFieldType.subject: return subjectsRequest(v);
            case RequestFieldType.section: return undefined;
            case RequestFieldType.judge: return undefined;
            case RequestFieldType.skill: return skillsRequest(v);
        }
    };    

    let response = () => {
        switch (type) {
            case RequestFieldType.activity: return activities;
            case RequestFieldType.office: return offices;
            case RequestFieldType.subject: return subjects;
            case RequestFieldType.section: return undefined;
            case RequestFieldType.judge: return undefined;
            case RequestFieldType.skill: return skills;
        }
    };

    let parseValue = (value: any) => {
        switch (type) {
            case RequestFieldType.activity: return value;
            case RequestFieldType.office: return value.name;
            case RequestFieldType.subject: return value;
            case RequestFieldType.section: return value;
            case RequestFieldType.judge: return value;
            case RequestFieldType.skill: return value.name;
        }
    };

    let placeholder = () => {
        switch (type) {
            case RequestFieldType.activity: return strings.requestField.placeholder.activity;
            case RequestFieldType.office: return strings.requestField.placeholder.office;
            case RequestFieldType.subject: return strings.requestField.placeholder.subject;
            case RequestFieldType.section: return strings.requestField.placeholder.section;
            case RequestFieldType.judge: return strings.requestField.placeholder.judge;
            case RequestFieldType.skill: return strings.requestField.placeholder.skill;
        }
    };

    let hasObject = () => {
        if (selectedObj === undefined && onChange) { 
            onChange(new SelectOption(undefined, undefined)); 
            return;
        }

        if (canBeText() || !onChange) { 
            return;
        }

        let _value = selectedObj && selectedObj.label === tempValue ? selectedObj.value : undefined;
        onChange(new SelectOption(_value, _value));
    };

    return (
        <form onSubmit={e => { e.preventDefault(); }}>
            <div className={double ? 'request__autosuggest__container double' : 'request__autosuggest__container'} style={{ width: '100%' }}>
                <InputAutosuggest
                    label={undefined}
                    placeholder={placeholder()}
                    suggestions={response() || []}
                    onChange={(v) => {
                        setSelectedObj(undefined);
                        setTempValue(v);
                    }}
                    value={tempValue}
                    onFetch={request}
                    onSelect={(data) => {
                        setError(undefined);
                        setSelectedObj(data);
                    }}
                    onBlur={() => {
                        if (type === RequestFieldType.office) {
                            setError(!selectedObj ? 'Seleziona un ufficio dalla lista' : undefined);
                        }
                    }}
                    error={error}
                />
            </div>
        </form>
    );
};

export default RequestField;
