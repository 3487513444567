import React, { Fragment, useEffect, useState } from "react";
import SubscriptionCard from "components/subscription/SubscriptionCard";
import { Link, useHistory } from "react-router-dom";
import UserService from "services/UserServices";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionInfoType } from "values/values";
import { setSubscription } from "store/reducers/SubscriptionReducer";
import { User } from "store/types/User";
import { IUserReducer } from "store/reducers/UserReducer";
import logo from '../image/logo-collega.svg';
import skill from '../image/skill__image.svg';
import _ from 'lodash';

const Subscriptions: React.FC = () => {
  const history = useHistory();

  const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

  const [subscriptionInfoResponse, subscriptionInfoError, subscriptionInfoRequest] = UserService.useSubscriptionsInfoAPI()
  const [subscriptionsInfo, setSubscriptionsInfo] = useState<Array<SubscriptionInfo>>([])

  const dispatch = useDispatch();

  useEffect(() => {
    subscriptionInfoRequest()
  }, [])

  useEffect(() => {
    if (subscriptionInfoResponse) {
      filteredList()
    }
  }, [subscriptionInfoResponse])


  const borderColor = (type: SubscriptionInfoType) => {
    switch (type) {
      case SubscriptionInfoType.SKILLS: return 'rgba(32, 138, 134, 1)';
      case SubscriptionInfoType.PROFESSIONAL: return 'rgba(236, 200, 79, 1)';
      case SubscriptionInfoType.GOLD: return 'rgba(171, 130, 83, 1)';
      default: return '';
    }
  }


  const filteredList = () => {
    var list = []

    if (!User.isGold(currentUser)) {
      list.push(_.first(subscriptionInfoResponse.filter((s) => s.type == SubscriptionInfoType.GOLD))!)
    }

    if (!User.isProfessional(currentUser)) {
      list.push(_.first(subscriptionInfoResponse.filter((s) => s.type == SubscriptionInfoType.PROFESSIONAL))!)
    }

    list.push(_.first(subscriptionInfoResponse.filter((s) => s.type == SubscriptionInfoType.SKILLS))!)
    setSubscriptionsInfo(list)
  }

  return (
    <Fragment>
      <div className={'header-subscription'}>
        <Link to={'/dashboard'}><img className={'logo subscription-logo'} src={logo} alt="Logo collega" /></Link>
       
      </div>
      <div className={"subscriptions-container"}>
        <div className={'wrapper-login'}>
          <div className={'login'} style={{ paddingTop: '8px' }}>
          <div className="collega-details__title">{'Abbonamenti'}</div>

            <div style={{ marginTop: 16 }} className={'login__form'}>
              {subscriptionsInfo && subscriptionsInfo.map((subscriptionInfo: SubscriptionInfo) => {
                return <SubscriptionCard
                  key={subscriptionInfo.id}
                  subscription={subscriptionInfo}
                  borderColor={borderColor(subscriptionInfo.type!)}
                  onClick={() => {
                    dispatch(setSubscription(subscriptionInfo))
                    history.push(`/subscription?type=${subscriptionInfo.type}`)
                  }}
                />
              })}
            </div>
            
            {/* TODO: Found Better Solution for image position */}
            <div className={'login__image'} style={{marginTop: -50}}> 
              <img style={{ width: 450 }} src={skill} alt="Logo collega" />
            </div>
          </div>
        </div>
      </div>

    </Fragment>

  );
};

export default Subscriptions;
