import React, { Fragment, useEffect } from "react";
import CollegaProfessional from "./CollegaProfessional";
import CollegaGold from "./CollegaGold";
import CollegaCompetenze from "./CollegaCompetenze";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SubscriptionInfoType } from "values/values";
import Utility from "utils/utils";
import { useDispatch, useSelector } from "react-redux";

import logo from '../../../image/logo-collega.svg';
import skill from '../../../image/skill__image.svg';
import gold from '../../../image/gold__image.svg';
import professional from '../../../image/professional__image.svg';
import UserService from "services/UserServices";

import _ from 'lodash';
import { ISubscriptionReducer, setPrice, setSubscription } from "store/reducers/SubscriptionReducer";


const Subscription: React.FC = () => {

  const location = useLocation()
  const history = useHistory();

  const [subscriptionInfoResponse, subscriptionInfoError, subscriptionInfoRequest] = UserService.useSubscriptionsInfoAPI()


  const subscription = useSelector((state: ISubscriptionReducer) => state.subscription.subscription);
  const dispatch = useDispatch();


  const contentMap = {
    [SubscriptionInfoType.PROFESSIONAL]: <CollegaProfessional subscriptionInfo={subscription!} />,
    [SubscriptionInfoType.GOLD]: <CollegaGold />,
    [SubscriptionInfoType.SKILLS]: <CollegaCompetenze subscriptionInfo={subscription!} />,
  };

  const imageMap = {
    [SubscriptionInfoType.PROFESSIONAL]: professional,
    [SubscriptionInfoType.GOLD]: gold,
    [SubscriptionInfoType.SKILLS]: skill,
  };

  useEffect(() => {
    if (location) {
      subscriptionInfoRequest()
    }
  }, [location])

  useEffect(() => {
    if (subscriptionInfoResponse) {
      const queryParams = new URLSearchParams(location.search);
      let subscription_type = queryParams.get('type')

      let _subscription = _.first(subscriptionInfoResponse.filter((s) => s.type == subscription_type))
      dispatch(setSubscription(_subscription))
    }

  }, [subscriptionInfoResponse])


  // useEffect(() => {
  //   if (location.state.subscription_type && !subscription) window.location.href = '/dashboard'
  // }, [subscription])

  const handleContinue = () => {
    if (subscription == undefined) return
    if (subscription.type === SubscriptionInfoType.PROFESSIONAL) {
      history.push("/account-confirm", { subscription: subscription });

    } else {
      if (subscription.type === SubscriptionInfoType.GOLD) {
        dispatch(setPrice(Number(subscription.price)))
      }
      history.push("/subscription-plan");
    }
  };


  return (
    <Fragment>
      <div className={'header-subscription'}>
        <Link to={'/dashboard'}><img className={'logo subscription-logo'} src={logo} alt="Logo collega" /></Link>
      </div>
      <div className={'wrapper-login'}>
        {subscription && subscription && <div className={'login'} style={{ paddingTop: '30px' }}>
          <div style={{ position: 'relative' }} className={'login__form'}>

            {contentMap[Utility.subscriptionType(subscription)]}

            <button
              title={"Continua"}
              className={'button-primary'}
              onClick={handleContinue}>
              <span>{'Prosegui'}</span>
            </button>

          </div>
          <div className={'login__image'}>
            <img style={{ width: 450 }} src={imageMap[Utility.subscriptionType(subscription)]} alt="Logo collega" />
          </div>
        </div>}
      </div>
    </Fragment>
  );
};

export default Subscription;
