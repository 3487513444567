import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import questionMark from '../../image/question-mark.svg';
import edit from '../../image/edit.svg';
import gold from '../../image/gold.svg';
import { Skill } from 'store/types/Profile';
import { IUserReducer } from "../../store/reducers/UserReducer";
import Input from "../common/Input";
import { useSelector } from "react-redux";
import UserService from 'services/UserServices';
import Textarea from 'components/common/TextArea';
import toast, { Toaster } from 'react-hot-toast';
import colors from 'values/colors';


interface Props {
    skill: Skill;
    disabled?: boolean;
    isGold?: boolean;
    readonly?: boolean
    onPress?: () => void;
}

const SkillProfileItem: React.FC<Props> = React.memo((props) => {
    const isGenerica = props.skill.name === 'Generica';

    const [descriptionValue, setDescriptionValue] = useState('');
    const [showTextArea, setShowTextArea] = useState<boolean>(false);
    const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
    let [updateUserResponse, updateUserError, updateUserRequest] = UserService.useUpdateUserMeAPI()

    useEffect(() => {
        if (updateUserResponse) toast.success("Descrizione aggiornata!")
    }, [updateUserResponse])

    useEffect(() => {
        setDescriptionValue(props.skill.description != undefined ? props.skill.description : '')
    }, [])

    return (
        <>
            <div className="container">
                <div className="skill-container-row">
                    <div className="skill-text-wrapper">
                        <div className="skill">{`★ ${props.skill.name}`}</div>

                        {/* TODO: Fix Tooltip icon */}
                        {isGenerica && (
                            <div className="tooltip">
                                {!props.readonly && <img src={questionMark} alt="question-icon" className="question-icon" />}
                                <span className="tooltip-text">
                                    La competenza Generica ti permette di ricevere notifiche per tutte le nuove attività non specifiche.
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                {props.isGold && props.skill.name != 'Generica' && (
                    <div className="content">
                        <div className="descriptionContent">
                            <img src={gold} alt="collega icon" style={{ marginTop: props.readonly ? -2 : undefined, marginBottom: 16 }} className={'icon'} />

                            {/* TODO: add input style*/}
                            <form onSubmit={(event) => {
                                event.preventDefault();

                                if (currentUser !== undefined) {
                                    const skills = currentUser!.profile.skills.filter((s: Skill) => s.id === props.skill.id);
                                    if (skills.length !== 0) {
                                        const skill = skills[0];
                                        skill.description = descriptionValue;
                                        updateUserRequest(currentUser);
                                    }
                                }
                            }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    {!showTextArea && <div style={{ color: descriptionValue == '' ? colors.light_gray : colors.black }} className="skillDescription">{descriptionValue != '' ? descriptionValue : 'Descrizione mancante'}</div>}

                                    {showTextArea && <div style={{ width: '100%', marginRight: 16 }}>
                                        <Textarea
                                            placeholder={'Descrizione mancante'}
                                            value={descriptionValue}
                                            onChange={(event?: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                if (event) {
                                                    setDescriptionValue(event.target.value);
                                                }

                                            }}
                                        />

                                        <button className={'button-primary'} style={{ height: 40, alignSelf: 'flex-end' }} onClick={(event) => {
                                            event.preventDefault();

                                            if (currentUser !== undefined) {
                                                const skills = currentUser!.profile.skills.filter((s: Skill) => s.id === props.skill.id);
                                                if (skills.length !== 0) {
                                                    const skill = skills[0];
                                                    skill.description = descriptionValue;
                                                    updateUserRequest(currentUser);
                                                }
                                            }
                                        }} >{'Salva'}
                                        </button>
                                    </div>}

                                    <div style={{ alignSelf: 'flex-start' }}>
                                        {!props.readonly && <img
                                            src={edit}
                                            alt="edit-icon"
                                            onClick={() => {
                                                setShowTextArea(!showTextArea)
                                            }}
                                        />}
                                    </div>
                                </div>
                                {/* <Input
                                    placeholder={'Descrizione mancante'}
                                    value={descriptionValue}
                                    onChange={(event?: React.ChangeEvent<HTMLInputElement>) => {
                                        if (event) {
                                            setDescriptionValue(event.target.value);
                                        }
                                    }}
                                /> */}

                                {/* <Textarea
                                    placeholder={'Descrizione mancante'}
                                    value={descriptionValue}
                                    onChange={(event?: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        if (event) {
                                            setDescriptionValue(event.target.value);
                                        }

                                    }}
                                /> */}

                                {/* <button className={'button-primary'} style={{height: 40, alignSelf: 'flex-end'}} onClick={(event) => {
                                    event.preventDefault();

                                    if (currentUser !== undefined) {
                                        const skills = currentUser!.profile.skills.filter((s: Skill) => s.id === props.skill.id);
                                        if (skills.length !== 0) {
                                            const skill = skills[0];
                                            skill.description = descriptionValue;
                                            updateUserRequest(currentUser);
                                        }
                                    }
                                }} >{'Aggiungi'}
                                </button> */}
                            </form>
                        </div>

                        {/* <img
                        src={edit}
                        alt="edit-icon"
                    /> */}
                    </div>
                )}
            </div>
        </>
    );
});

export default SkillProfileItem;
