const strings = {
  save: 'Salva',
  loading: 'Loading',
  signIn: {
    title: 'Accedi',
    actionForgotPassword: 'Recupera password',
    actionConfirm: 'Accedi',
    signUpQuestion: 'Non sei un utente Collega?',
    actionSignUp: 'Registrati'
  },
  forgotPassword: {
    headerTitle: 'Recupera password',
    text: 'Inserisci l\'email o il numero di telefono con cui hai effettuato la registrazione.',
    actionConfirm: 'Recupera Password',
    actionBackToLogin: 'Torna al login',
    popup: {
      title: 'E-Mail inviata',
      text: 'Controlla la tua email. Ti abbiamo inviato un link per recuperare la tua password.',
      close: 'Chiudi'
    }
  },
  // Field Label
  fieldLabelFirstRoleName: 'Quale è la tua qualifica?',
  fieldLabelFirstRoleSummary: 'La tua qualifica',
  fieldLabelFirstNameRegister: 'Quale è il tuo nome?',
  fieldLabelFirstNameSummary: 'Il tuo nome',
  fieldLabelLastNameRegister: 'Quale è il tuo cognome?',
  fieldLabelLastNameSummary: 'Il tuo cognome',
  fieldLabelEmail: 'E-mail o Telefono',
  fieldLabelEmailRegister: 'Quale è la tua email?',
  fieldLabelEmailSummary: 'La tua email',
  fieldLabelPassword: 'Password',
  fieldLabelPasswordRegister: 'Crea la tua password',
  fieldLabelPasswordSummary: 'La tua password',
  fieldLabelFiscalCode: 'Digita il tuo codice fiscale',
  fieldLabelFiscalCodeSummary: 'Il tuo Codice Fiscale è',
  fieldLabelPhone: 'Quale è il tuo numero di telefono?',
  fieldLabelPhoneSummary: 'Il tuo numero di telefono è',
  fieldLabelCity: 'Il tuo studio legale si trova a',
  fieldLabelTerms: 'Ho preso visione dei Termini di Servizio',
  fieldLabelPrivacy: 'Ho preso visione della Privacy Policy',

  // Field Placeholder
  fieldPlaceholderFirstName: 'Inserisci il tuo nome',
  fieldPlaceholderLastName: 'Inserisci il tuo cognome',
  fieldPlaceholderEmailPhone: 'Inserisci la tua e-mail o il tuo telefono',
  fieldPlaceholderEmail: 'Inserisci la tua e-mail o il tuo telefono',
  fieldPlaceholderPassword: 'Inserisci la tua password',
  fieldPlaceholderFiscalCode: 'Inserisci il tuo codice fiscale',
  fieldPlaceholderPhone: 'Inserisci il tuo numero di telefono',
  fieldPlaceHolderCounterpart: 'Controparte',
  fieldPlaceHolderPart: 'Parte',
  fieldPlaceHolderNotice: 'Nessuno',
  fieldPlaceHolderSearch: 'Cerca',
  fieldPlaceHolderAnnotation: 'Aggiungi delle note: Es. prima sezione',
  fieldPlaceHolderLink: 'Inserisci link',
  fieldPlaceHolderCode: 'Inserisci codice',

  // Tooltip
  tooltip: {
    passwordTitle: 'Requisiti Password',
    passwordText: 'La password deve essere lunga almeno 8 caratteri e contenere almeno un numero e una lettera',
    done: 'Segna come evasa',
    todo: 'Segna come non evasa',
    postpone: 'Rinvia',
    edit: 'Modifica',
    delete: 'Elimina'
  },
  //
  errors: {
    invalidField: 'Campo non valido',
    invalidEmptyPassword: 'La password non può essere vuota',
    invalidEmailPhone: 'E-mail o telefono non valido',
    invalidEmail: 'E-mail non valida',
    invalidPassword: 'Password non valida',
    invalidFiscalCode: 'Codice Fiscale non valida',
    invalidPhone: 'Numero di telefono non valido',
  },
  //
  onBoarding: {
    signIn: 'Accedi',
    signUp: 'Registrati',
    first: {
      pre: 'Benvenuto!',
      title: 'Collega è l\'App dedicata a tutti gli avvocati italiani che ti permette di rendere la tua attività professionale più organizzata.',
      subtitle: 'Trova sostituti per le tue attività, gestisci la tua agenda legale, organizza la tua formazione e molto altro!',
    },
    card1: {
      pre: 'Trova il sostituto ideale!',
      text: 'Utilizza l’applicazione per trovare in pochi secondi un domiciliatario, un sostituto per l’udienza oppure un collega che possa compiere delle attività di cancelleria in uno degli Uffici Giudiziari Italiani.'
    },
    card2: {
      pre: 'Diventa il sostituto ideale!',
      text: 'Renditi disponibile ad accettare incarichi. Rispondi alle richieste dei tuoi colleghi e porta a compimento le attività che ti verranno assegnate.'
    },
    card3: {
      pre: 'Verbali d\’udienza',
      text: 'Crea e condividi i Verbali d’Udienza da qualunque dispositivo, Pc, Tablet, Smartphone e invita i tuoi colleghi a lavorare sullo stesso documento.'
    },
    card4: {
      pre: 'Agenda Legale',
      text: 'Programma e gestisci tutti i tuoi appuntamenti, adempimenti e udienze nella tua Agenda Legale, senza farti sfuggire più nulla.'
    },
    store: {
      pre: 'Cosa aspetti?',
      text: 'Installala ora!',
    },
  },

  signUp: {
    headerTitle: 'Registrazione',
    fifth: {
      cityLabel: 'La città dello studio legale',
      cityPlaceholder: 'Seleziona la città in cui operi',
    },
    summary: {
      headerTitle: 'Riepilogo',
      headerDescription: 'Controlla i dati che hai inserito e procedi con la creazione del tuo account!',
    },
    stepReview: {
      phone: 'Numero di telefono',
      edit: 'Modifica',
      save: 'Salva',
      create: 'Crea il tuo Account',
    },
    help: 'Serve aiuto?',
    help_subject: 'Collega Web - Richiesta di supporto'
  },

  //
  profile: {
    passwordHidden: '*********************',
    basicCoWorker: {
      title: 'Collega Basic',
      qualificationTitle: 'Qualifiche',
      qualificationSubtitle: 'Scegli una o entrambe le qualifiche riportate',
      addSkillButton: 'Aggiungi competenza'
    },
    emptySpecificSkillBanner: {
      title: 'Competenze Specifiche',
      subtitile: 'Aggiungi delle competenze \nspecifiche da associare al tuo profilo'
    },
    emptyMainSkillBanner: {
      title: 'Competenze Specifiche',
      subtitile: 'Scegli la tua prima competenza specifica e ricevi richieste per attività più affini alle tue capacità.'
    }
  },

  //DASHBOARD
  dashboard: {
    title: 'Home',
    agenda: 'Agenda Legale',
    activityCard: {
      title: 'Richiedi Attività',
      subtitle: 'Trova un domiciliatario, un sostituto di udienza, oppure un collega che possa svolgere attività di cancelleria',
    },
    iSkilledCard: {
      title: 'iSkilled',
      subtitle: 'Corsi e Servizi',
    },
    collegaTraining: 'Collega Shop',
    activitiesCard: {
      title: 'Attività',
      subtitle: 'Non ci sono eventi programmati per i prossimi giorni',
    },
    news: 'News',
  },

  //EMPTY
  empty: {
    title: 'Non c’è niente qui per ora,\npassa dopo!',
  },

  //HEADER PROFILE
  headerProfile: {
    cta: 'Vai al profilo',
    logout: 'Logout',
    today: 'Oggi'
  },

  //NAVMENU
  navMenu: {
    request_activities: 'Richiedi Attività',
    home: 'Home',
    activity_request: 'Richieste da Te',
    activity_proposal: 'Disponibilità',
    activity_assignements: 'Incarichi Ricevuti',
    agenda: 'Agenda Legale',
    records: 'Verbale d\'Udienza',
    subscriptions: 'Abbonamenti',
    news: 'News',
    iSkilled: 'iSkilled',
    iSkilled2: ' Corsi di formazione',
    shop: 'Collega Shop',
    collegaTraining: 'Servizi e Prodotti',
    share: 'Condividi app con i tuoi colleghi',
    shareSubject: 'Collega App',
    shareMessage: 'Ciao, scarica la nuova app Collega!%0D%0A%0D%0Ahttps://app.collegaonline.it',
    problem: 'Segnala un problema',
    support_email: {
      address: 'info@collegaonline.it',
      subject: 'Feedback Collega Web',
      message: 'Caro team dell\'applicazione Collega, ho utilizzato la vostra applicazione e desidererei condividere il mio feedback: '
    }
  },

  agenda: {
    more: 'carica altri eventi',
    header: 'I tuoi impegni',
    wrapper: 'Nessun elemento trovato',
    add: {
      headerTitle: 'Crea Impegno',
      notice: 'Avviso',
      addNotice: 'Aggiungi un Avviso',
      judge: 'Giudice',
      addJudje: 'Aggiungi un Giudice',
      judicialOffice: 'Ufficio Giudiziario',
      addJudicialOffice: 'Aggiungi Ufficio Giudiziario',
      nrg: 'NRG',
      addNrg: 'Aggiungi il codice NRG',
      addAnnotation: 'Vuoi aggiungere delle note?',
      type: 'Che tipo di evento vuoi creare?',
      activity: 'Quali attività  devi svolgere?',
      allDay: 'Tutto il giorno',
      date: 'In quale data?',
      start: 'Ora Inizio',
      end: 'Ora Fine',
      part: 'Definisci una Parte',
      counterpart: 'Definisci una Controparte',
      expense1Action: 'Aggiungi spese esclusa IVA',
      expense1Label: 'Spese esclusa IVA',
      expense1Placeholder: 'Inserisci le spese esclusa IVA',

      expense2Action: 'Aggiungi spese imponibili',
      expense2Label: 'Spese Imponibili',
      expense2Placeholder: 'Inserisci le spese imponibili',
      //
      expense3Action: 'Aggiungi compenso',
      expense3Label: 'Compenso',
      expense3Placeholder: 'Inserisci compenso',
    },
    //
    edit: {
      headerTitle: 'Modifica Impegno',
      action: {
        modify: 'Modifica'
      },
      postpone: {
        headerTitle: 'Rinvia',
        action: 'Rinvia Udienza'
      }
    },
    event: {
      items: {
        activity: 'Attività  da svolgere',
        notice: 'Ricordami',
        nrg: 'NRG',
        expence1: 'Spese Imponibili',
        expence2: 'Spese Escluse IVA',
        expence3: 'Spese Compenso',
        notes: 'Note',
        notesPlaceholder: 'Nessuna nota inserita',
        judge: 'Giudice',
        office: 'Ufficio Giudiziario'
      }
    },
    modal: {
      title: 'Elimina impegno',
      copy: 'Confermi di voler eliminare l\'evento',
      cta: 'Conferma',
      ctaUndo: 'Annulla',
    },
  },
  //
  records: {
    headerTitle: 'Verbale di Udienza',
    link: 'Copia link',
    create: 'Creati da me',
    share: 'Condivisi con me',
    //
    actionSheet: {
      options: {
        create: 'Crea verbale',
      }
    },
    //
    add: {
      headerTitle: 'Crea Verbale',
      share: 'Condivisione',
      link: 'Link di condivisione',
      accessCode: 'Codice di accesso',
      go: 'Vai al verbale'
    },
    right: {
      empty: {
        question: 'Vuoi creare un nuovo verbale di udienza?',
        copy: 'In questo momento non ci sono verbale attivi.'
      },
      shared: {
        copy: 'Inserisci il link e il codice che ti sono stati inviati in basso per partecipare alla scrittura del verbale di udienza.',
        link: 'Link',
        code: 'Codice',
        action: {
          go: 'Scrivi nel verbale'
        }
      }
    },
    modal: {
      title: 'Attenzione!',
      copy: 'Non puoi modificare nulla dopo aver confermato.',
      action: {
        confirm: 'Conferma',
        cancel: 'Annulla'
      }
    }
  },
  //ACTIVITY FILTER
  activityFilter: {
    all: 'Tutte',
    demands: {
      pending: 'Da Assegnare',
      assigned: 'Assegnate',
      completed: 'Concluse da Pagare',
      paid: 'Concluse Pagate',
      anulled: 'Annullate',
      expired: 'Scadute',
    },
    candidates: {
      pending: 'Nuove',
      accepted: 'In attesa di assegnazione',
      rejected: 'Rifiutate',
      // dead: 'Assegnate ad un Collega',
      dead: 'Non più disponibili',
    },
    managements: {
      assigned: 'Assegnate',
      completed: 'Concluse in attesa di pagamento',
      paid: 'Concluse e pagate',
    }
  },
  //ACTIVITY REQUEST
  activityRequest: {
    demands: 'Richieste da Te',
    candidates: 'Disponibilità',
    managements: 'Incarichi Ricevuti',
  },
  //ACTIVITY PANEL
  activityPanel: {
    modal: {
      candidate: {
        title: 'Candidatura inviata!',
        copy: 'Riceverai una notifica se l\'attività ti verrà assegnata.',
        cta: 'Ho capito!',
      },
      conclude: {
        title: 'Hai concluso l\'attività in tutte le sue parti?',
        copy: 'Una volta inviata la conferma della conclusione dell\'attivià non potrai tornare indietro.',
        cta: 'Si, l’attività è conclusa',
        ctaUndo: 'L\'attività non è ancora conclusa',
      },
      ended: {
        title: 'Attività conclusa!',
        copy: 'Puoi trovare le attività concluse nell\'apposita sezione "attività in attesa di pagamento',
        cta: 'Attività in attesa di pagamento',
        ctaUndo: 'Vai alla home',
      },
      incomplete: {
        title: 'Completa il tuo Profilo!',
        copy: 'Per inviare la candidatura è necessario avere un profilo completo',
        cta: 'Completa il tuo profilo',
        ctaUndo: 'Più tardi',
      },
    },
    pending: 'Collega disponibile',
    pendings: 'Colleghi disponibili',
    assigned: 'Assegnata a',
    button: {
      cancel: 'Annulla l\'attività',
      paid: 'L\'attività è stata conclusa e pagata',
      accept: 'Invia la tua candidatura',
      reject: 'Rifiuta',
      conclude: 'Attività conclusa',
    },
    activity: {
      office: 'L’attività verrà svolta presso:',
      activity: 'Attività da svolgere:',
      money: 'Compenso (IVA e CPA escluse)',
      vat_cpa: '*IVA e CPA escluse',
      subject: 'Materia',
      skill: 'Competenza',
      agreed: 'Da concordare',
      notes: 'Note',
    },
  },
  //ACTIVITY PROFILE
  activityProfile: {
    modal: {
      assigned: {
        title: 'Assegnata!',
        copy: 'Hai assegnato l\'incarico a ',
        copy2: ' svolgerà l\’attività per tuo conto, Terminata l\'attività riceverai una notifica per procedere con il pagamento.',
        cta: 'Visualizza attività assegnate',
        ctaUndo: 'Chiudi',
      },
      certified: {
        copy: 'Profilo Collega Professional. Il profilo Collega Professional offre più visibilità, più competenze e la gestione di due Studi Legali',
        cta: 'Ok',
      },
    },
    skills: 'Competenze',
    certified: {
      title: 'Collega Professional',
      subtitle: 'Profilo',
      b: 'Collega Professional',
    },
    info: {
      personal: {
        title: 'Informazioni personali',
        email: 'La sua e-mail',
        phone: 'Il suo numero di telefono',
        fiscal_code: 'Il suo codice fiscale è',
      },
      professional: {
        title: 'Informazioni professionali',
        city: 'Comune in cui opera',
        vat: 'La sua partita IVA',
        pec: 'La sua PEC',
        register_number: 'Il suo numero di iscrizione all\'albo',
        coa_office: 'c.o.a. di riferimento',
      },
      lawfirm: {
        title: 'Il suo Studio legale',
        name: 'Nome dello studio legale',
        city: 'Città dello studio legale',
        city2: 'Secondo comune in cui operi',
        lawyer: 'Avvocato',
        description: 'Descrizione',
        address: 'L\'indirizzo dello studio legale',
        zip: 'CAP',
        phone: 'Il numero di telefono dello studio',
        fax: 'Il numero di fax dello studio',
      },
    },
    button: 'Assegna Attività',
  },

  //ACTIVITY STATUSES
  activityStatuses: {
    count: ' Collega disponibile',
    counts: ' Colleghi disponibili',
    demand: {
      label: {
        send: 'Inviata',
        expiring: 'In Scadenza',
        pending: 'In Attesa di Assegnazione',
        assigned: 'Assegnata',
        rejected: 'Rifiutata',
        execution: 'In Esecuzione',
        ended: 'Conclusa',
        toPay: 'Da Pagare',
        paid: 'Pagata',
        canceled: 'Annullata',
        deleted: 'Eliminata',
        expired: 'Scaduta',
        notAssigned: 'Non più disponibile',
        // notAssigned: 'Assegnata ad un Collega',
        new: 'Nuova',
        waiting: 'In attesa di pagamento',
        payed: 'Pagamento ricevuto',
      },
    },


  },

  //NEWS
  news: {
    title: 'News',
  },

  //COURSES
  courses: {
    title: 'Elenco dei corsi',
    credits: 'Crediti formativi:',
    price: 'Prezzo:',
    free: 'GRATIS',
    euro: '€',
    duration: 'Durata corso:',
    lesson: 'Durata lezione:',
    minutes: 'minuti',
    registration: 'Durata iscrizione:',
    days: 'giorni',
    objective: 'Obiettivi del corso e sommario',
    showMore: 'Mostra di più',
    showAttachment: 'Vedi allegato',
    professors: 'Docenti',
    courseList: 'Elenco Lezioni',
    deadline: 'Scadenza accreditamento:',
    subscribe: 'Iscriviti',
    subscribeFree: 'Iscriviti Gratis',
    subscribing: 'Iscriviti al corso',
    paypal: 'Paga con PayPal',
    alert: 'L’accreditamento per questo corso scade tra 7 giorni, dopodiché non potrai più seguire le lezioni.',
    complete: 'Iscrizione al corso completata',
    pay: 'Il pagamento è andato a buon fine.',
    courses: 'Vai all’elenco dei corsi',
    toCourses: 'Vai ai miei corsi',
    purchasedTicket: 'Acquistato',
    expiredTicket: 'Scaduto',
    back: 'Torna ai corsi',
    empty: 'Non hai ancora acquistato corsi, vedi se nell’elenco c’è qualcosa che ti interessa.',
    modal: {
      title: 'Accedi al tuo account',
      copy: 'Per iscriverti al corso è necessario che tu acceda al tuo account. ',
      cta: 'Accedi',
      ctaUndo: 'Annulla'
    },
    purchased: {
      title: 'I miei corsi',
      lesson: 'Lezione',
      buy: 'Data di acquisto:',
      deadline: 'Scadenza corso:',
      expired: 'Scaduto il:',
    },
    form: {
      label: 'Inserisci i tuoi dati di fatturazione',
      address: 'Qual’è il tuo indirizzo?',
      cf: 'Il tuo codice fiscale?',
      vat: 'Partita IVA (Se disponibile)',
      pec: 'PEC',
      proceed: 'Procedi con il pagamento',
      proceedFree: 'Procedi',
    }

  },

  //PROFILE CERTIFICATION
  profileCertification: {
    modal: {
      title: 'Non riusciamo a verificare i tuoi dati',
      copy: 'Controlla che siano corretti.',
      cta: 'Modifica i dati',
    },
    verify: 'Verifica Profilo',
    input: {
      name: {
        label: 'Quale è il tuo nome?',
        placeholder: 'Il tuo nome',
      },
      surname: {
        label: 'Quale è il tuo cognome?',
        placeholder: 'Il tuo cognome',
      },
      fiscal_code: {
        label: 'Il tuo codice fiscale?',
        placeholder: 'Il tuo codice fiscale',
      },
    },
    button: 'Verifica su CNF',
    login: {
      title: 'Ottimo! Abbiamo verificato i tuoi dati',
      pay: 'Puoi procedere con il pagamento.',
      paypal: 'Paga con Paypal',
    }
  },

  //PROFILE EDIT
  profileEdit: {
    title: 'Il tuo Profilo',
    step: {
      img: 'Immagine profilo',
      qualification: {
        title: 'La tua ',
        title2: 'qualifica',
        placeholder: 'Seleziona la tua qualifica',
      },
      skills: {
        title: 'La tue ',
        title2: 'competenze',
      },
      personal: {
        title: 'La tue ',
        title2: 'informazioni personali',

        userTitle: 'Informazioni personali',
        pass: '********',
        passhide: '••••••••',

        birth: 'La tua data di nascita',
        day: 'GG',
        month: 'MESE',
        year: 'ANNO',
      },
      professional: {
        title: 'La tue ',
        title2: 'informazioni professionali',

        userTitle: 'Informazioni professionali',
        place: 'Comune in cui operi',
        place2: 'Secondo comune in cui operi',
        input: {
          vat: {
            label: 'La tua Partita Iva',
            placeholder: 'Inserisci la tua partita Iva',
          },
          pec: {
            label: 'La tua PEC',
            placeholder: 'Inserisci la tua PEC',
          },
          register_number: {
            label: 'Il tuo numero di iscrizione all’albo',
            placeholder: 'Inserisci il tuo numero di iscrizione',
          },
          coa: {
            label: 'C.O.A. di riferimento',
            placeholder: 'Inserisci il tuo riferimento',
          },
        }
      },
      lawfirm: {
        title: 'Il tuo ',
        title2: 'studio legale',
        disclaimer: '*Campo non modificabile. Se necessario scrivi a info@collegaonline.it',
        userTitle: 'Lo studio in cui lavori',

        input: {
          name: {
            label: 'Nome dello studio legale',
            placeholder: 'Inserisci il tuo studio legale',
          },
          lawyer: {
            label: 'Avvocato',
            placeholder: 'Inserisci il tuo nome',
          },
          description: {
            label: 'Descrizione',
            placeholder: 'Inserisci descrizione',
          },
          address: {
            label: 'L\'indirizzo dello studio legale',
            placeholder: 'Inserisci l\'indirizzo',
          },
          city: {
            label: 'Città dello studio legale',
            placeholder: 'Inserisci la città',
          },
          cap: {
            label: 'CAP dello studio legale',
            placeholder: 'Inserisci il cap',
          },
          phone: {
            label: 'Numero di telefono dello studio',
            placeholder: 'Inserisci il numero di telefono',
          },
          fax: {
            label: 'Numero di FAX dello studio',
            placeholder: 'Inserisci il numero di fax',
          },
          city2: {
            label: 'Secondo comune in cui operi',
            placeholder: 'Inserisci il comune secondario',

            userLabel: 'Città dello studio legale',
          },
        },
      },
    },
    ctaUndo: 'Annulla',
    cta: 'Salva Profilo',
  },
  //PROFILE LOCATIONS
  profileLocations: {
    title: 'Foro di competenza',
    subtitle: 'Puoi inserire',
    subtitle2: ' un secondo comune per il tuo studio legale',
    input: {
      city: 'Il tuo studio legale nel Comune principale',
      city2: {
        label: 'Il tuo studio legale nel Comune secondario',
        placeholder: 'Inserisci secondo comune',
      }
    },
    cta: 'Fatto',
  },

  //PROFILE SKILLS
  profileSkills: {
    basicTitle: 'Scegli la competenza principale',
    specificTitle: 'Seleziona le competenze specifiche',
    subtitle: 'Seleziona competenze',
    cta: 'Fatto',
  },

  //PROFILE STEPS
  profileSteps: {
    title: 'Completa il tuo profilo',
    complete: 'Il tuo profilo è completo',
    save: 'Salva profilo',
    next: 'Avanti',
    step1: {
      title: 'Scegli ',
      title2: 'un’immagine per il tuo profilo',
    },
    step2: {
      title: 'Seleziona ',
      title2: 'le tue competenze',
    },
    step6: {
      title: 'Collega Professional',
      subtitle: 'Diventa Collega Professional. Sfrutta la potenza di un profilo Collega Professional. Potrai inserire ',
      subtitle2: 'nuove competenze',
      subtitle3: ', un secondo foro ',
      subtitle4: 'per il tuo studio legale',
      subtitle5: 'acquisire priorità',
      subtitle6: 'in ricerche e candidature.',
      ctaUndo: 'Più tardi',
      cta: 'Diventa Collega Professional',
    },
  },

  //USER PROFILE
  userProfile: {
    modal: {
      expirationDescription: 'Il tuo profilo Collega Professional verrà rinnovato il {0} ad un costo di {1}€ + IVA.',
      expirationDescriptionEnd: 'Il tuo profilo Collega Professional terminerà il {0}.',
      cancelSubscription: 'Annulla abbonamento',
      cta: 'Ok',
      profile: 'Collega Professional',
      certified: 'Profilo',
      collega: 'Collega Professional',
    },
    popup: {
      title: 'Annulla abbonamento',
      text: 'Sei sicuro di voler annullare l\'abbonamento?',
      action: 'Si',
      close: 'No'
    },
    title: 'Il tuo Profilo',
    skills: 'Competenze',
    toComplete: {
      title: 'Il tuo profilo non è completo!',
      subtitle: 'Per poter utilizzare tutte le funzionalità che Collega mette a disposizione hai bisogno di un profilo completo.',
      cta: 'Completa il tuo profilo',
    },
    toCertify: {
      title: 'Collega Professional',
      subtitle: 'Sfrutta le potenzialità di un profilo <b>Collega Professional</b></br>Riceverai <b>TUTTE</b> le notifiche del Foro e <b>PRIMA</b> degli utenti Basic.</br>Potrai inserire <b>2 nuove competenze gratuite</b>, sarai il <b>primo della lista</b> dei disponibili, avrai un <b>Profilo Certificato</b> e una <b>maggiore visibilità</b> all’interno dell’App. Inoltre, inserendo un <b>secondo comune</b> riceverai le richieste per <b>entrambi i fori</b>.',
      cta: 'Diventa Collega Professional',
    },
    isCertified: {
      title: 'Hai un profilo Collega Professional',
      subtitle: 'Aggiungi nuove competenze, inserisci un secondo foro per il tuo studio legale.',
      cta: 'Inserisci nuove competenze',
      cta2: 'Inserisci un secondo studio',
    },
    certified: {
      title: 'Collega Professional',
      subtitle: 'Congratulazioni! Sei un utente ',
      subtitle2: 'Collega Professional'

    },
    cta: 'Modifica profilo',
    settings: {
      title: 'Impostazioni',
      email: 'Notifiche Email',
      notification: 'Notifiche Push'
    },
    terms: {
      title: 'Termini e Condizioni',
      terms: 'Termini di Servizio',
      privacy: 'Privacy Policy',
    }
  },

  //REQUEST ACTIVITIES
  requestActivities: {
    title: 'Richiedi attività!',
    cta: 'Avanti',
    send: 'Invia la richiesta',
    obligatory: 'Obbligatorio',
    type: {
      domiciliary: 'Avvocato Domiciliatario',
      cancellery: 'Attività di Cancelleria',
      substitute: 'Sostituto in Udienza',
    },
    modal: {
      opened: {
        title: 'Stai per inviare la tua richiesta!',
        copy: 'Attenzione! stai per inviare una richiesta che riceveranno tutti gli avvocati dell\'ufficio giudiziario selezionato.',
        cta: 'Procedi con l\'invio',
        ctaUndo: 'Non inviare la richiesta',
      },
      confirmed: {
        title: 'La tua richiesta è stata inviata',
        copy: 'Controlla lo stato delle richieste inviate nella sezione, "Attività Richieste da Te" oppure vai alla Home.',
        cta: 'Attività Richieste',
        ctaUndo: 'Vai alla home',
      },
      guest: {
        title: 'La tua richiesta è stata inviata',
        copy: 'Riceverai una email all\'indirizzo da te indicato contenente la password per accedere a Collega® e vedere l\'elenco degli avvocati già disponibili e da contattare.',
        copy2: 'Grazie per aver utilizzato Collega®!',
        cta: '',
        ctaUndo: '',
      },
    },
    other: 'Inserisci importo*',
    tbd: 'Da concordare',
    step1: {
      title: 'Chi',
      title2: ' stai cercando?',
    },
    step2: {
      title: 'Per ',
      title2: 'quale',
      title3: ' attività',
      fee: 'Quale è il compenso?'
    },
    step3: {
      title: 'Dove',
      title2: ' verrà svolta l\'attività',
      matter: 'Qual è la materia?',
      section: 'Qual è la sezione?',
      judge: 'Chi è il giudice?',
    },
    step4: {
      title: 'È richiesta',
      title2: ' una competenza specifica?',
      subtitle: 'Puoi aggiungere alla tua richiesta una',
      subtitle2: ' competenza specifica',
      subtitle3: ', in modo tale da ricevere candidature',
      subtitle4: ' solo da colleghi che hanno quella competenza associata.',
      subtitle5: ' In alternativa puoi selezionare la',
      subtitle6: ' competenza generica',
      subtitle7: ' per inviare la richiesta, indistintamente, a tutti i colleghi del foro.',
      helpText: {
        title: 'Avvocati disponibili',
        subtitle: 'Percentuale di avvocati disponibili nel foro indicato associati alla competenza selezionata.\nSe la tua richiesta non riceverà risultati, allora riproponila come competenza generica.'
      }
    },
    step5: {
      title: 'Quando',
      title2: ' verrà svolta l\'attività',
      date: 'Il giorno...',
      time: 'Alle ore...',
      checkbox: {
        all: 'Invia a tutti gli Avvocati',
        certified: 'Invia solo a Collega Professional',
        aiga: 'Invia solo agli Associati AIGA',
      },
    },
    step6: {
      title: 'Inserisci',
      title2: ' i seguenti dati per poter inviare la richiesta.',
      sendContent: 'Inviando la richiesta i tuoi dati saranno registrati su Collega s.r.l. al fine di erogare il servizio richiesto, potrai consultarli, modificarli e cancellarli con una semplice richiesta. Nessun abbonamento e nessun costo!'
    },
  },

  //REQUEST FIELD
  requestField: {
    placeholder: {
      activity: 'Inserisci l\'attività',
      office: 'Inserisci l\'uffici giudiziario',
      subject: 'Inserisci una materia',
      section: 'Inserisci una sezione',
      judge: 'Inserisci un giudice',
      skill: 'Inserisci competenza'
    },
  },

  //GLOBAL ERROR MODAL
  globalError: {
    modal: {
      title: 'Errore',
      copy: 'È stato rilevato un errore',
      cta: 'Chiudi',
    },
  },
  //UPDATES MODAL
  updatesModal: {
    step1: {
      title: 'Nuovo profilo utente!',
      description: 'Da oggi è attiva una nuova divisione delle competenze all’interno del tuo profilo.',
      buttonText: 'Avanti'
    },
    step2: {
      title: 'Competenza Generica',
      description: 'Abbiamo introdotto la competenza "Generica" in modo da ricevere notifiche per tutte le attività non specifiche.',
      buttonText: 'Avanti'
    },
    step3: {
      title: 'Competenze Specifiche',
      description: 'Potrai ricevere notifiche per attività affini alle tue competenze. Inizia selezionando la prima competenza specifica!',
      newUserDescription: 'Ricevi notifiche per attività affini alle tue competenze. Inizia selezionando la prima competenza specifica!',
      buttonText: 'Scegli competenza',
      secondaryButtonText: 'Più tardi'
    },
  },

  //PAGE ERROR
  pageError: {
    title: '404',
    subtitle: 'Siamo spiacenti, pagina non trovata!',
    copy: 'La pagina che stai cercando non è al momento raggiungibile.',
    cta: 'Torna in home',
  },

  // SUBSCRIPTION FLOW
  subscriptions: {
    cards: {
      linkFindMore: 'Scopri',
      professional: {
        title: 'Professional',
        list: {
          listItem1: "2 competenze",
          listItem2: "Secondo comune in cui operi",
          listItem3: "Ricezione prioritaria delle richieste",
          listItem4: "Posizione in evidenza nelle liste dei candidati",
        }
      },
      gold: {
        title: 'Gold',
        list: {
          listItem1: "Possibilità di aggiungere la descrizione delle proprie competenze",
          listItem2: "Possibilità di aggiungere la descrizione dello Studio Legale",
        }
      },
      competenze: {
        title: 'Competenze',
        list: {
          listItem1: "Possibilità di acquistare competenze aggiuntive in pacchetti da:",
        }
      },
    },
    collega: {
      cta: 'Prosegui',
      automaticAnnualRenewal: 'Rinnovo automatico',
      rinnovoAutomatico: 'Automatic renewal',
      year: 'anno',
      month: 'mese',
      professional: {
        title: 'Collega Professional',
        list: {
          listItem1: "Selezionare altre 2 competenze",
          listItem2: "Selezionare il secondo comune in cui operi",
          listItem3: "Ricezione prioritaria delle richieste",
          listItem4: "Posizione in evidenza nelle liste dei candidati",
        },
        select: {
          label: 'Scegli il secondo comune in cui operi',
          hint: 'Il prezzo dell’abbonamento varia a seconda del comune selezionato. Sarà possibile modificarlo in seguito, potrebbe variare.'
        }
      },
      gold: {
        title: 'Collega Gold',
        list: {
          listItem1: "Aggiungere una descrizione alle tue competenze",
          listItem2: "Aggiungere la descrizione dello Studio Legale in cui lavori",
        }
      },
      competenze: {
        title: 'Competenze aggiuntive',
        list: {},
        description: 'Scegli il pacchetto di competenze tra quelli proposti più adatto alle tue esigenze',
        select: {
          label: 'Scegli il numero di competenze',
          hint: 'Entrambi i pacchetti sono acquistabili più di una volta e andranno rinnovati manualmente ogni anno'
        }
      },
    },
    collegaDetails: {
      title: 'Conferma i tuoi dati',
      description: 'Caro utente Collega, per poterti fregiare del titolo di “Collega Professional” all’interno dell’App, dovrai certificare di essere un avvocato oppure un praticante selezionando una delle scelte sottostanti e cliccare sul pulsante “Certifico”. In questo modo certificherai, sotto la tua responsabilità la veridicità dei dati inseriti nel tuo profilo Collega e la tua iscrizione presso il COA di appartenenza che hai indicato, con esonero da ogni responsabilità per la società Collega s.r.l. fornitrice del servizio.',
      button: 'Certifico'
    },
    subscriptionPlan: {
      professional: {
        title: 'Verifica completata',
        description: 'Scegli il piano che desideri, dopodichè potrai procedere al pagamento sicuro con rinnovo automatico alla scadenza. L’abbonamento può essere annullato in qualsiasi momento.',
      },
      gold: {
        title: 'Scegli il tuo piano',
        description: 'Scegli il piano che desideri, dopodichè potrai procedere al pagamento sicuro tramite PayPal con rinnovo automatico alla scadenza. L’abbonamento può essere annullato in qualsiasi momento.',
      },
      competenze: {
        title: 'Scegli il tuo piano',
        description: 'Scegli il piano che desideri, dopodichè potrai procedere al pagamento sicuro tramite PayPal con rinnovo automatico alla scadenza. L’abbonamento può essere annullato in qualsiasi momento.',

      },
    },
    radioButton: {
      annual: 'Annuale',
      halYearly: 'Semestrale',
      monthly: 'Mensile',
      automaticAnnualRenewal: 'Rinnovo automatico',
      automaticSixMonthlyRenewal: 'Rinnovo automatico'
    },
    accordionPromo: {
      title: 'Inserisci un codice sconto',
      validatePromoBtn: 'Valida',
      placeholder: 'Enter promo'
    }
  }
};



export default strings;
